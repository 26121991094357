<template>
  <admin>
    <metatag title="Dashboard"></metatag>
    <page-header></page-header>

    <p class="mb-2">Filter by dealers</p>
    <v-row>
      <v-col v-if="user.is_admin" md="4">
        <v-select
          append-icon="mdi-chevron-down"
          :items="zones"
          item-text="text"
          item-value="value"
          label="Filter by zones"
          outlined
          clearable
          hide-details
          clear-icon="mdi-close-circle-outline"
          background-color="selects"
          @change="setZones($event)"
          return-object
        >
        </v-select>
      </v-col>
      <v-col v-if="user.is_admin" md="4">
        <v-select
          append-icon="mdi-chevron-down"
          :items="groups"
          item-text="text"
          item-value="value"
          label="Filter by groups"
          outlined
          clearable
          hide-details
          clear-icon="mdi-close-circle-outline"
          background-color="selects"
          @change="setGroups($event)"
          return-object
        >
        </v-select>
      </v-col>
      <v-col v-if="user.is_admin" md="4">
        <dealer @update:dealer="setDealer" />
      </v-col>
    </v-row>
    <br />
    <p class="mb-2">Filter by date</p>
    <v-row>
      <v-col cols="4">
        <v-text-field
          type="date"
          label="Start Date"
          outlined
          clearable
          hide-details
          background-color="selects"
          v-model="filters.startDate"
          :rules="rules.startDate"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field
          type="date"
          label="End Date"
          class="dt-text-field"
          outlined
          clearable
          hide-details
          background-color="selects"
          v-model="filters.endDate"
          :rules="rules.endDate"
          :max="dateToday"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn
          style="height: 93%"
          large
          :disabled="loading"
          :loading="loading"
          exact
          color="primary"
          @click="submitFilter"
        >
          <v-icon left>mdi-download-box-outline</v-icon>
          {{ "Submit" }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card class="my-4">
          <v-card-title>
            <h3 title="Sales Funnel Summary" class="mb-1">
              Sales Funnel Summary
            </h3>
          </v-card-title>
          <v-card-text>
            <pie-chart :data="salesFunnelSummary"></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="my-4">
          <v-card-title>
            <h3 title="Sales Funnel Summary" class="mb-1">
              Online Reservations
            </h3>
          </v-card-title>
          <v-card-text>
            <pie-chart :donut="true" :data="status"></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="my-4">
          <v-card-title>
            <h3 title="Sales Funnel Summary" class="mb-1">Converted Leads</h3>
          </v-card-title>
          <v-card-text>
            <column-chart :data="convertedLeads" :stacked="true"></column-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </admin>
</template>

<script>
import Vue from "vue";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import { mapActions, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import Dealer from "@/components/Dealer/Dealer";
import DateRange from "@/components/DateRange/DateRange";
import * as helpers from "@/core/helpers";

Vue.use(Chartkick.use(Chart));

export default {
  data() {
    return {
      dealerZoneId: null,
      dealerGroupId: null,
      dealerId: null,
      date: null,
      salesFunnelSummary: [],
      convertedLeads: [],
      status: [],
      user: null,
      containerGraphData: [],
      dateToday: this.formatDate(new Date(Date.now()), "YYYY-MM-DD"),
      loading: false,
      filters: {
        startDate: "",
        endDate: "",
        zones: "",
        groups: "",
        dealers: "",
      },
      rules: {
        startDate: [(value) => !!value || "Start date is required"],
        endDate: [(value) => !!value || "End date is required"],
      },
    };
  },
  components: {
    Dealer,
    DateRange,
  },
  computed: {
    ...mapGetters({
      dealers: "dealer/GET_DEALERS",
      zones: "dealerZone/GET_DEALER_ZONES",
      groups: "dealerGroup/GET_DEALER_GROUPS",
      dealer: "dealer/GET_DEALER",
      graphData: "graph/GET_GRAPH_DATA",
    }),
  },
  async mounted() {
    await this.getDealerZones();
    await this.getDealerGroups();
    await this.getDealers();

    Chart.plugins.register({
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every((item) => item === 0)) {
          let ctx = chart.chart.ctx;
          let width = chart.chart.width;
          let height = chart.chart.height;

          // chart.clear();
          ctx.save();
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText("No available data", width / 2, height / 2);
          ctx.restore();
        }
      },
    });

    this.setDateRange([this.dateToday, this.dateToday]);
  },
  created() {
    if (!localStorage.user) {
      this.$router.push({ name: "login" });
    } else {
      this.user = JSON.parse(localStorage.user);
    }
  },
  methods: {
    ...mapActions({
      getDealerZones: "dealerZone/getDealerZones",
      getDealerGroups: "dealerGroup/getDealerGroups",
      getDealers: "dealer/getDealers",
      getGraphData: "graph/getGraphData",
    }),
    showTip() {
      document.querySelector(".tooltip-message").classList.remove("d-none");
    },
    showTip() {
      document.querySelector(".tooltip-message").classList.remove("d-none");
    },
    editItem(item) {
      (this.editedItem.upload_date = item.upload_date),
        (this.inquiryDialog = true);
    },
    formatDate(item, format) {
      return helpers.format_date(item, format);
    },
    setDateRange(dateRange) {
      this.filters.startDate = dateRange[0];
      this.filters.endDate = dateRange[1];

      this.filter();
    },
    filter() {
      let dealer_zone = this.filters.zones;
      let dealer_group = this.filters.groups;
      let dealer_id = this.filters.dealers;
      let date = [this.filters.startDate, this.filters.endDate];

      let data = {
        dealer_zone: dealer_zone,
        dealer_group: dealer_group,
        dealer_id: dealer_id,
        date: date,
      };

      this.getGraphData(data).then(() => {
        this.populateData();
      });
    },
    populateData() {
      this.containerGraphData = [];
      this.containerGraphData = cloneDeep(this.graphData.data);
      this.salesFunnelSummary = this.containerGraphData.sales_funnel;
      this.convertedLeads = this.containerGraphData.converted_leads;
      this.status = this.containerGraphData.reservation_status;
    },

    async setZones(e) {
      this.filters.zones = e ? e.value : null;

      //Set groups filter
      await this.getDealerGroups({
        region_types: [],
        zones: this.filters.zones ? [this.filters.zones] : [],
      });

      //Set dealer filter
      this.filters.groups = "";
      this.filters.dealers = "";

      await this.getDealers({
        region_types: [],
        zones: this.filters.zones ? [this.filters.zones] : [],
        groups: this.filters.groups ? [this.filters.groups] : [],
      });
    },
    async setGroups(e) {
      this.filters.groups = e ? e.value : null;

      //Set dealer filter
      this.filters.dealers = "";

      await this.getDealers({
        region_types: this.filters.regions ? [this.filters.regions] : [],
        zones: this.filters.zones ? [this.filters.zones] : [],
        groups: this.filters.groups ? [this.filters.groups] : [],
      });
    },
    async setDealer() {
      this.filters.dealers = this.dealer;
    },
    submitFilter() {
      this.filter();
    },
  },
};
</script>

<style></style>
